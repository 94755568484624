<template>
  <div :class="$style.container">
    <ui-slider
      :items="days"
      :gap="1.6"
      :items-per-slide="itemsPerSlide"
      ref="slider"
    >
      <template #default="{ items }">
        <div :class="$style.slide">
          <fantasy-calendar-day
            v-for="(item, index) in items"
            :day="item"
            :key="index"
            :is-past="!item.can_set_slot"
            :is-completed="item.is_completed"
            :show-timer="timerDayId === item.id"
            :is-claimed="!!item.memberStatus.rewarded_at"
            :points="item.memberStatus.points"
            :selected="item.id === dayId"
            :game="game"
            @click="onChooseDay(item)"
            v-analytics.click.gtm="['paripass', 'fantasy', 'calendar_click']"
          />
        </div>
      </template>
    </ui-slider>
  </div>
</template>

<script>
import FantasyCalendarDay from '~/components/fantasy/calendar/day'
import UiSlider from '~/components/ui/slider'

export default {
  name: 'FantasyCalendar',
  components: {
    UiSlider,
    FantasyCalendarDay
  },
  props: {
    days: {
      type: Array,
      default: () => []
    },
    dayId: {
      type: Number,
      default: null
    },
    game: {
      type: String,
      required: true
    }
  },
  emits: ['input'],
  computed: {
    itemsPerSlide() {
      return this.$matchMedia.down('sm') || this.$matchMedia.up('md') ? 6 : 7
    },
    timerDayId() {
      const day = this.days.find(day => day.can_set_slot)
      return day ? day.id : null
    }
  },
  methods: {
    onChooseDay(item) {
      this.$emit('input', item.id)
    },
    scrollToDay() {
      this.$nextTick(() => {
        const index = this.days.findIndex(day => day.id === this.dayId)
        if (index > -1) {
          this.$refs.slider.goToItemIndex(index)
        }
      })
    }
  },
  mounted() {
    this.scrollToDay()
  },
  watch: {
    dayId() {
      this.scrollToDay()
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: calc(30em + 3.2em);
  @include down(md) {
    width: 100%;
  }
  @include down(sm) {
    width: calc(30em + 3.2em);
  }
}
.slide {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.6em;
  padding-bottom: 1em;
  @include down(md) {
    grid-template-columns: repeat(7, 1fr);
  }
  @include down(sm) {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
