<template>
  <div :class="$style.container">
    <div :class="$style.title" v-if="rewards.length">
      <span :class="$style.text">{{ title }}</span>
    </div>

    <div v-drag-scroll-x :class="$style.scroll">
      <div :class="$style.content">
        <div :class="$style.items">
          <fantasy-game-day-rewards-item
            v-for="(item, i) in availableRewards"
            :class="$style.item"
            :reward="item"
            :percent="item.top_percent"
            :is-available="item.is_available"
            :can-claim="canClaim && isCompleted"
            :is-claimed="isClaimed"
            :day-id="dayId"
            :game="game"
            :key="i"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "Rewards": "Возможные награды на день",
    "Your reward": "Твоя награда"
  }
}
</i18n>

<script setup>
import FantasyGameDayRewardsItem from '~/components/fantasy/game-day/rewards/reward'
import { vDragScrollX } from '~/directives/drag-scroll-x'

const { t } = useI18n()

const $props = defineProps({
  rewards: {
    type: Array,
    default: () => []
  },
  dayId: {
    type: Number,
    required: true
  },
  isClaimed: {
    type: Boolean,
    default: false
  },
  isCompleted: {
    type: Boolean,
    required: true
  },
  game: {
    type: String,
    required: true
  }
})

const isAvailableReward = computed(
  () => $props.rewards.some(item => item.is_available) && $props.isCompleted
)
const title = computed(() => {
  return $props.isCompleted && isAvailableReward.value
    ? t('Your reward')
    : t('Rewards')
})
const canClaim = computed(() => !$props.isClaimed && isAvailableReward.value)
const availableRewards = computed(() => {
  return isAvailableReward.value
    ? $props.rewards.filter(item => item.is_available)
    : $props.rewards
})
</script>

<style lang="scss" module>
.container {
  --rewards-gap: 2.4em;
}
.title {
  text-align: center;
  padding: 1.6em 0 1.2em;
  .text {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 1.6em;
    line-height: 120%;
    letter-spacing: -0.02em;
  }
}
.scroll {
  @include hide-scrollbar();
  overflow: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-padding: var(--rewards-gap);
}
.items {
  display: flex;
  justify-content: center;
  padding: 0 var(--rewards-gap);
}
.item {
  scroll-snap-align: start;
  & + & {
    margin-left: var(--rewards-gap);
  }
}
.content {
  display: inline-block;
  min-width: 100%;
  scroll-snap-align: start;
}
</style>
