<template>
  <article :class="classes" @click="onClick" :data-game="game">
    <div :class="$style.content">
      <div :class="$style.icon">
        <img :src="cardIcon" alt="" />
      </div>
      <h3 :class="$style.title">
        <span v-if="cardType === 'change'">{{ t('change') }}</span>
        <span v-else>{{ t('title', { cardType: t(cardType) }) }}</span>
      </h3>
      <div :class="$style.action" v-if="!locked && game !== 'dota'">
        <ui-button
          :class="$style.btn"
          theme="accent"
          size="medium"
          uppercase
          fill
          block
          v-analytics.click.gtm="['paripass', 'fantasy', 'choice_click']"
        >
          {{ t('Pick') }}
        </ui-button>
      </div>
    </div>
  </article>
</template>

<i18n>
{
  "ru": {
    "title": "Выбери {cardType}",
    "sniper": "Снайпера",
    "fighter": "Бойца",
    "middle": "Центр",
    "main": "Основу",
    "support": "Поддержку",
    "change": "Сменить карточку",
    "Pick": "выбрать"
  }
}
</i18n>

<script setup>
import sniper from '../../assets/types/sniper-icon.svg?url'
import fighter from '../../assets/types/fighter-icon.svg?url'
import main from '../../assets/types/icon-type-main.svg?url'
import middle from '../../assets/types/icon-type-middle.svg?url'
import support from '../../assets/types/icon-type-support.svg?url'
import changeIcon from '../../assets/types/icon-type-none.svg?url'
import UiButton from '~/components/ui/button/index.vue'

const { t } = useI18n()
const $style = useCssModule()
const $emit = defineEmits(['click'])

const $props = defineProps({
  locked: {
    type: Boolean,
    default: false
  },
  cardType: {
    type: String,
    required: true,
    validator: cardType =>
      ['sniper', 'fighter', 'main', 'middle', 'support'].includes(cardType)
  },
  transparent: {
    type: Boolean,
    default: false
  },
  game: {
    type: String,
    required: true
  },
  change: {
    type: Boolean,
    default: false
  }
})

const icons = {
  sniper,
  fighter,
  main,
  middle,
  support
}

const cardIcon = computed(() =>
  $props.change && $props.game === 'dota' ? changeIcon : icons[$props.cardType]
)

const classes = computed(() => [
  $style.slot,
  $props.locked && $style.locked,
  $props.transparent && $style.transparent
])

const onClick = $event => {
  if (!$props.locked) {
    $emit('click', $event)
  }
}
</script>

<style lang="scss" module>
.slot {
  width: 27em;
  height: 100%;
  box-sizing: border-box;
  border-radius: 1.2em;
  aspect-ratio: 2/3;

  &:after {
    border-radius: inherit;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.icon {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.title {
  text-align: center;
  transition: color 0.2s ease;

  span {
    font-weight: 700;
    font-size: 1.6em;
    line-height: 1.2;
    text-transform: uppercase;
    display: inline-block;
    font-family: var(--main-font);
    @include down(md) {
      font-size: 1.4em;
    }
    @include down(sm) {
      font-size: 1.2em;
    }
  }
}
.action {
  padding: 4em;
  width: 100%;
  @include down(md) {
    padding: 2em;
    .btn {
      padding: 1.4em 0.5em;
    }
  }
  @include down(sm) {
    padding: 1.2em;
  }
}

.transparent {
  backdrop-filter: blur(4px);
  border: 1px solid rgba(#00c7b1, 0.5);
  &:after {
    opacity: 0.6;
  }
  &:before {
    display: none;
  }
}

.locked {
  pointer-events: none;
  .title {
    visibility: hidden;
  }
}

[data-game='cs'].slot {
  &:after {
    background-color: #00020e;
  }
  .icon {
    width: 100%;
    padding: 4em;
    border-radius: 0.2em;
    margin-bottom: 2em;
    aspect-ratio: 1.6;
    transition: background 0.2s ease;

    @include down(md) {
      padding: 2em;
      margin-bottom: 1em;
    }
  }
  &.transparent {
    border: 0 none;
    backdrop-filter: none;
    &:after {
      opacity: 1;
    }
  }
}

[data-game='dota'].slot {
  border: 1px solid #ffffff1a;
  padding: 6.7em 0 6em;

  @include down(md) {
    padding: 3.3em 1.6em 1.6em;
  }

  &:after {
    background-color: #080e1f;
    background-image: url('@/components/fantasy/common/slot/assets/cover.png');
  }
  &:before {
    content: '';
    z-index: 2;
    position: absolute;
    border: 1px solid #ffffff1a;
    border-radius: inherit;
    top: 0.8em;
    left: 0.8em;
    right: 0.8em;
    bottom: 0.8em;
  }
  &:hover {
    cursor: pointer;
    .icon {
      background: var(--app-active-color);
    }
  }
  .icon {
    background: #0f1936;
    width: 8em;
    height: 8em;
    padding: 2em;
    border-radius: 0.2em;
    margin-bottom: 2em;
    transition: background 0.2s ease;

    @include down(md) {
      width: 4em;
      height: 4em;
      padding: 1.1em;
      margin-bottom: 1em;
    }
  }
}
</style>
