<template>
  <div :class="classes" :data-game="game">
    <div :class="$style.image">
      <ui-coin
        v-if="isCoin"
        :code="getCoinCode(reward.resource.source_id)"
        :value="reward.amount"
      />
      <img
        v-else-if="imageUrl"
        :src="imageUrl"
        :alt="reward.resource.name"
        :title="reward.resource.name"
      />
    </div>
    <div :class="$style.actions" v-if="canClaim">
      <ui-button
        :loading="pending"
        :class="$style.claim"
        fill
        size="medium"
        theme="accent"
        uppercase
        @click="claim"
        v-analytics.click.gtm="['paripass', 'fantasy', 'get_prize_click']"
      >
        {{ t('Claim') }}
      </ui-button>
    </div>
    <div :class="$style.name" v-else-if="!isClaimed">
      <span :class="$style.text">Топ {{ percent }}% игроков</span>
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "Claim": "Получить"
  }
}
</i18n>

<script setup>
import UiCoin from '~/components/ui/coin'
import UiButton from '~/components/ui/button'
import { useFantasySeasonStore } from '@/stores/fantasy/season.js'
import { useSettingsStore } from '~/stores/common/settings'

const { t } = useI18n()
const $style = useCssModule()
const fantasySeasonStore = useFantasySeasonStore()
const { $dialog } = useNuxtApp()
const { getCoinCode } = useSettingsStore()

const pending = ref(false)

const $props = defineProps({
  percent: {
    type: Number,
    default: 0
  },
  reward: {
    type: Object,
    required: true
  },
  isClaimed: {
    type: Boolean,
    default: false
  },
  isAvailable: {
    type: Boolean,
    default: false
  },
  canClaim: {
    type: Boolean,
    default: false
  },
  dayId: {
    type: Number,
    required: true
  },
  game: {
    type: String,
    required: true
  }
})

const classes = computed(() => [
  $style.item,
  $props.canClaim && $style.isAvailable
])

const isCoin = $props.reward.resource?.source?.id === 'coin'
const imageUrl = $props.reward.resource?.image_url?.small

const claim = async () => {
  try {
    pending.value = true
    await fantasySeasonStore.claimDayReward($props.dayId)
    $dialog({
      content: defineAsyncComponent(
        () => import('~/components/fantasy/popups/claimed')
      ),
      wrapper: null,
      contentProps: {
        reward: $props.reward
      }
    })
  } finally {
    pending.value = false
  }
}
</script>

<style lang="scss" module>
.item {
  --fantasy-game-day-reward-bg: #252c3e;
  --fantasy-game-day-reward-img-bg: #2f3648;
  --fantasy-game-day-reward-text-color: var(--app-active-color);

  border-radius: 0.6em;
  display: flex;
  align-items: center;
  background-color: var(--fantasy-game-day-reward-bg);
}
.image {
  border-radius: 0.6em;
  width: 14em;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--fantasy-game-day-reward-img-bg);
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @include down(sm) {
    height: 4em;
  }
}
.claim {
  margin-left: 1.2em;
}
.name {
  padding: 1.2em;
  white-space: nowrap;
  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 120%;
    color: var(--fantasy-game-day-reward-text-color);
  }
}

.item[data-game='dota'] {
  --fantasy-game-day-reward-bg: #0a1125;
  .image {
    --fantasy-game-day-reward-img-bg: #111e41;
  }
  .text {
    --fantasy-game-day-reward-text-color: #17ace2;
  }
}

.item.isAvailable {
  --fantasy-game-day-reward-bg: transparent;
}
</style>
