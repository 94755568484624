<template>
  <div :class="classes">
    <span v-if="showMessage" :class="$style.text">
      {{ t('Make choices for the new day') }}
    </span>
  </div>
</template>

<i18n>
{
  "ru": {
    "Make choices for the new day": "Сделай выбор на новый день"
  }
}
</i18n>

<script>
export default {
  name: 'FantasyCommonOverlayTeamLock',
  props: {
    showMessage: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classes() {
      return [this.$style.container, this.showMessage && this.$style.blur]
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  border-radius: inherit;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
  padding: 0 5em;
  text-align: center;
}
.blur {
  backdrop-filter: blur(10px);
}
.text {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: 2.4em;
  line-height: 142%;
  text-transform: uppercase;
  color: #ffffff;
}
</style>
