<template>
  <div :class="$style.container">
    <div :class="$style.main">
      <ui-avatar
        :class="$style.avatar"
        :src="$auth.account.avatar"
        :border="$auth.account.styles.avatarBorderColor"
        :sticker="$auth.account.styles.sticker"
        size="xxlarge"
      />
      <div :class="$style.content">
        <ui-username
          :class="$style.username"
          :color="$auth.account.styles.usernameColor"
        >
          {{ $auth.account.username }}
        </ui-username>

        <div :class="$style.score">
          <div :class="$style.item">
            <div :class="$style.title">{{ t('Points') }}</div>
            <div :class="$style.value">{{ formattedPoints }}</div>
          </div>
          <div :class="$style.item">
            <div :class="$style.title">{{ t('Position') }}</div>
            <div :class="$style.value">{{ rankValue }}</div>
          </div>
        </div>
        <a
          :class="$style.rating"
          href="javascript: void(0);"
          @click.prevent="onOpenRating"
        >
          {{ t('Rating') }}
        </a>
      </div>
    </div>

    <div :class="$style.actions">
      <fantasy-button-collection
        :class="$style.btn"
        :count="cardsCount"
        to="/fantasy/collection"
        v-analytics.click.gtm="['paripass', 'fantasy', 'collection_click']"
      />
      <ui-button
        :class="$style.btn"
        to="/fantasy/shop"
        size="large"
        theme="accent"
        fill
        uppercase
        v-analytics.click.gtm="['paripass', 'fantasy', 'buy_click']"
      >
        {{ t('Buy') }}
      </ui-button>
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "Buy": "Купить",
    "Rating": "Смотреть рейтинг",
    "Points": "Очки",
    "Position": "Место"
  }
}
</i18n>

<script setup>
import FantasyButtonCollection from '~/components/fantasy/common/button-collection'
import UiAvatar from '~/components/ui/avatar'
import UiUsername from '~/components/ui/username'
import UiButton from '~/components/ui/button'
import number from '@/utils/number'
import { useFantasyLeaderboardStore } from '@/stores/fantasy/leaderboard'
import UiLink from '~/components/ui/link/index.vue'

const { t } = useI18n()
const { $dialog } = useNuxtApp()

const $props = defineProps({
  cardsCount: {
    type: Number,
    default: 0
  },
  points: {
    type: Number,
    default: 0
  },
  rank: {
    type: Number,
    default: 0
  }
})

const fantasyLeaderboardStore = useFantasyLeaderboardStore()
const rankValue = computed(() => ($props.rank ? number($props.rank) : '-'))
const formattedPoints = computed(() => number($props.points))
const onOpenRating = () =>
  $dialog({
    wrapper: null,
    content: defineAsyncComponent(
      () => import('~/components/fantasy/popups/rating')
    ),
    fetchData: () => fantasyLeaderboardStore.fetchItems()
  })
</script>

<style lang="scss" module>
.container {
}
.main {
  display: flex;
}
.content {
  min-width: 0;
  width: 100%;
  @include down(sm) {
    flex: 1 1;
  }
}
.avatar {
  margin-right: 2.6em;
  @include down(sm) {
    margin-right: 1.7em;
  }
}
.username {
  font-style: normal;
  font-weight: 700;
  font-size: 2.4em;
  line-height: 142%;
  font-family: var(--primary-font);
}
.score {
  margin-top: 1.2em;
  margin-bottom: 0.8em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4em;
  width: 100%;
  min-width: 0;
  .item {
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 1.2em;
      line-height: 120%;
      color: rgba(255, 255, 255, 0.5);
    }
    .value {
      font-family: var(--primary-font);
      font-style: normal;
      font-weight: 700;
      font-size: 2.4em;
      line-height: 142%;
      white-space: nowrap;
    }
  }
}
.rating {
  color: var(--app-active-color);
  text-decoration-line: underline;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-size: 1.4em;
  line-height: 100%;
}
.actions {
  display: flex;
  align-items: center;
  margin-top: 3.2em;
  @include down(sm) {
    display: block;
    margin-top: 4em;
  }
  .btn {
    @include down(sm) {
      width: 100%;
      max-width: 100%;
    }
    &:last-child {
      margin-left: 1.6em;
      max-width: 18em;
      width: 100%;
      @include down(sm) {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-top: 1.6em;
      }
    }
  }
}
</style>
