<template>
  <div :class="classes" @click="$emit('click')">
    <p :class="$style.text">
      <span :class="$style.date">{{ formattedDay }}</span>
      {{ formattedMonth }}
    </p>

    <p v-if="showTimer" :class="$style.chooseText">
      <span>{{ t('Choose') }}</span>
    </p>

    <p v-if="isClaimed" :class="$style.receivedText">
      <span>{{ t('received') }}</span>
    </p>
    <span v-else-if="showPoints" :class="$style.points">
      {{ formattedPoints }}
    </span>

    <ui-timer
      v-if="showTimer"
      :class="$style.timer"
      :value="day.date_slot_lock"
      theme="default"
      to-ms
    />
  </div>
</template>

<i18n>
{
  "ru": {
    "Choose": "Выбрать",
    "received": "Получено"
  }
}
</i18n>

<script>
import UiTimer from '~/components/ui/time/timer'
import number from '~/utils/number'

export default {
  name: 'FantasyCalendarDay',
  components: {
    UiTimer
  },
  emits: ['click'],
  props: {
    day: {
      type: Object,
      required: true
    },
    showTimer: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    isPast: {
      type: Boolean,
      default: false
    },
    isClaimed: {
      type: Boolean,
      default: false
    },
    isCompleted: {
      type: Boolean,
      default: false
    },
    points: {
      type: Number,
      default: 0
    },
    game: {
      type: String,
      required: true
    }
  },
  computed: {
    date() {
      return this.$time(this.day.date_timestamp * 1000)
    },
    formattedDay() {
      return this.date.date.day.value
    },
    formattedMonth() {
      return this.date.t.month.slice(0, 3)
    },
    isTomorrow() {
      return this.date.tomorrow
    },
    isToday() {
      return this.date.today
    },
    isFuture() {
      return this.date.left < 0
    },
    showPoints() {
      return this.isCompleted && this.points && !this.isClaimed
    },
    classes() {
      return [
        this.$style.container,
        this.$style[`game-${this.game}`],
        {
          [this.$style.selected]: this.selected,
          [this.$style.received]:
            this.isClaimed ||
            (!this.points &&
              !this.isClaimed &&
              this.isPast &&
              !this.isTomorrow),
          [this.$style.available]: this.isPast && this.points && !this.isClaimed
        }
      ]
    },
    formattedPoints() {
      return number(this.points)
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  border-radius: 0.6em;
  box-sizing: border-box;
  padding: 0.8em 0 1.8em 1.6em;
  width: 10em;
  height: 10em;
  background: var(--fantasy-calendar-day-bg);
  cursor: pointer;
  transition:
    opacity 0.3s,
    box-shadow 0.3s;
  &:hover {
    opacity: 0.5;
  }
}

.selected {
  background: var(--fantasy-calendar-day-bg-selected);
  box-shadow: inset 0 0 0.1em 0.1em rgba(255, 255, 255, 0.6);
  cursor: default;
  &:hover {
    opacity: 1;
  }
}

.available {
  background: var(--fantasy-calendar-day-bg-available);
  .text {
    opacity: 0.3;
  }
}

.received {
  background: var(--fantasy-calendar-day-bg-available);
  .text {
    opacity: 0.3;
  }
}

.receivedText {
  margin-top: 2.6em;
  span {
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.2;
  }
}

.text {
  margin-bottom: 0.8em;
  font-size: 1.4em;
  font-family: var(--primary-font), sans-serif;
  font-weight: 700;
}

.points {
  font-weight: 700;
  font-size: 1.8em;
  line-height: 1.2;
  text-transform: uppercase;
}

.date {
  font-weight: 700;
  font-size: 2.4em;
  line-height: 1.2;
}

.chooseText {
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.3;
}

.timer {
  position: absolute;
  bottom: -0.9em;
  left: 50%;
  transform: translateX(-50%);
}

.game-cs {
  --fantasy-calendar-day-bg: linear-gradient(
    360deg,
    #b40091 0%,
    #673389 30.35%,
    #354470 61.98%,
    #252e41 100%
  );
  --fantasy-calendar-day-bg-available: linear-gradient(
    360deg,
    #00c7b1 0%,
    #1d6f97 33.35%,
    #2a3c74 61.49%,
    #262c40 100%
  );
  --fantasy-calendar-day-bg-received: rgba(37, 44, 62, 0.5);
}

.game-dota {
  --fantasy-calendar-day-bg: #15244e;
  --fantasy-calendar-day-bg-available: #2b4aa1;
  --fantasy-calendar-day-bg-received: #0f1936;
  --fantasy-calendar-day-bg-selected: #2b4aa1;

  .timer {
    --ui-timer-theme-bg: #17ace2;
    --ui-timer-theme-color: #fff;
  }
}
</style>
