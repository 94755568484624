import { createItemsStore } from '@/utils/pinia/index.js'

export const useFantasyLeaderboardStore = createItemsStore(
  'fantasy-leaderboard',
  {
    path: '/api/v1/fantasy/season/leaderboard',
    query: {
      expand: ['member.styles']
    }
  }
)
