<template>
  <div :class="classes">
    <ui-time
      format="future-time"
      @timeout="emit('timeout')"
      :value="value"
      :to-ms="toMs"
      :timeout="1000"
      clock
    />
  </div>
</template>

<script setup>
import UiTime from './index'

const style = useCssModule()
const emit = defineEmits(['timeout'])
const props = defineProps({
  value: {
    type: [Number, String],
    required: true
  },
  toMs: {
    type: Boolean,
    default: false
  },
  theme: {
    type: String,
    default: 'default',
    validator: v => ['default', 'primary', 'custom'].includes(v)
  }
})

const classes = computed(() => [style.container, style[`theme-${props.theme}`]])
</script>

<style lang="scss" module>
.container {
  display: inline-block;
  position: relative;
  text-align: center;
  padding: 0 1.4em;
  box-shadow: var(--ui-timer-theme-shadow);
  color: var(--ui-timer-theme-color);

  time {
    position: relative;
    z-index: 1;
    font-family: var(--primary-font), sans-serif;
    font-weight: 700;
    font-size: 1.4em;
    line-height: 186%;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 0.2em;
    transform: skew(-12deg);
    background: var(--ui-timer-theme-bg);
  }
}

.theme-default {
  --ui-timer-theme-color: #000;
  --ui-timer-theme-bg: #fff;
  --ui-timer-theme-shadow: 0 2px 16px rgba(19, 4, 37, 0.25);
}

.theme-primary {
  --ui-timer-theme-color: #fff;
  --ui-timer-theme-bg: #753bbd;
  --ui-timer-theme-shadow: 0 2px 16px rgba(19, 4, 37, 0.25);
}
</style>
