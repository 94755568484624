<template>
  <div :class="[$style.container, $style[`game-${game}`]]">
    <header :class="$style.header">
      <span :class="$style.text" v-if="date.today">{{ t('Today') }}:</span>
      <span :class="$style.text" v-else-if="isLocked">{{ t('Ended') }}:</span>
      <span :class="$style.text" v-else>{{ t('Choose') }}:</span>

      <div :class="$style.accent">
        <span :class="$style.text" v-if="isCompleted && !date.today">
          <span v-if="points > 0">+</span> {{ formattedPoints }}
          {{ t('points') }}
        </span>
        <ui-time v-else :class="$style.text" :value="timestamp" to-ms />
      </div>
      <p :class="$style['timer-wrapper']">
        <ui-timer
          v-if="!isLocked"
          :class="$style.timer"
          :value="lockTimestamp"
          to-ms
          theme="primary"
        />
      </p>
    </header>

    <div :class="$style.main">
      <fantasy-game-day-rewards
        :class="$style.rewards"
        :day-id="dayId"
        :is-completed="isCompleted"
        :is-claimed="isClaimed"
        :rewards="rewards"
        :game="game"
      />

      <div v-if="0" :class="$style.skipped">
        <div :class="$style.message">
          <span :class="$style.text">{{ t('Locked') }}</span>
        </div>
        <ui-button
          v-if="availableDayId && isLocked"
          fill
          uppercase
          theme="accent"
          size="medium"
          @click="onGoToDay(availableDayId)"
          v-analytics.click.gtm="['paripass', 'fantasy', 'choice_click']"
        >
          {{ t('Make choice') }}
        </ui-button>
      </div>

      <fantasy-game-day-team
        :class="$style.team"
        :positions="positions"
        :slots="slots"
        :day-id="dayId"
        :locked="isLocked"
        :show-overlay="showOverlay"
        :game="game"
      />
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "Today": "Сегодня",
    "Choose": "Сделай выбор",
    "Ended": "День Завершен",
    "points": "Очков",
    "Make choice": "Сделать выбор",
    "Locked": "Выбор на этот день закрыт, сделай выбор на новый день"
  }
}
</i18n>

<script setup>
import { useSettingsStore } from '~/stores/common/settings'
import FantasyGameDayRewards from '~/components/fantasy/game-day/rewards'
import FantasyGameDayTeam from '~/components/fantasy/game-day/team'
import UiTimer from '~/components/ui/time/timer'
import number from '~/utils/number'
import UiButton from '~/components/ui/button'
import UiTime from '~/components/ui/time'
import FantasyCalendarDay from '~/components/fantasy/calendar/day.vue'

const { $time } = useNuxtApp()
const settingsStore = useSettingsStore()
const { t } = useI18n()

const $emit = defineEmits(['select-day'])
const $props = defineProps({
  timestamp: {
    type: Number,
    default: 0
  },
  lockTimestamp: {
    type: Number,
    default: 0
  },
  dayId: {
    type: Number,
    required: true
  },
  isCompleted: {
    type: Boolean,
    required: true
  },
  isLocked: {
    type: Boolean,
    required: true
  },
  availableDayId: {
    type: Number,
    default: null
  },
  isClaimed: {
    type: Boolean,
    default: false
  },
  points: {
    type: Number,
    default: 0
  },
  rewards: {
    type: Array,
    default: () => []
  },
  positions: {
    type: Array,
    default: () => []
  },
  slots: {
    type: Array,
    default: () => []
  },
  game: {
    type: String,
    required: true
  }
})

const settings = computed(() => settingsStore.data)
const date = computed(() => $time($props.timestamp * 1000))
const isSkipped = computed(() => $props.isLocked && !$props.slots.length)
// const isFuture = computed(() => (date.value.left < 0))
// const showTimer = computed(() => ($props.availableDayId === $props.dayId))
const showOverlay = computed(() => isSkipped.value)
const formattedPoints = computed(() => number($props.points))

const onGoToDay = dayId => $emit('select-day', dayId)
</script>

<style lang="scss" module>
.header {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 2.4em;
    line-height: 142%;
  }
}

.team {
  margin: 0 calc(-1 * var(--container-offset));
  @include down(md) {
    padding: 0 0.8em;
  }
  @include down(sm) {
    padding: 0;
  }
}
.rewards {
  margin-bottom: 3.5em;
  @include down(md) {
    margin-bottom: 2.4em;
  }
}
.skipped {
  padding: 1.6em 2em 3.5em;
  text-align: center;
}
.message {
  margin-bottom: 1.2em;
  .text {
    font-family: var(--primary-font);
    font-weight: 700;
    font-size: 1.6em;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
  }
}

.accent {
  color: var(--app-active-color);
  margin-left: 1.2em;
}

.game-cs {
  &.container {
    --container-offset: 4.6em;
    margin: 0 var(--container-offset);

    @include down(md) {
      --container-offset: 0;
    }
  }

  .main {
    background: #11182a url(@/components/fantasy/game-day/assets/bg.png) repeat
      top left;
    padding-bottom: 1.6em;
    border-radius: 0 0 1.2em 1.2em;
    @include down(md) {
      padding-bottom: 1.4em;
    }
  }

  .header {
    background-color: #252c3e;
    border-radius: 1.2em 1.2em 0 0;
    padding: 1.6em;

    @include down(md) {
      flex-wrap: wrap;
    }
  }

  .timer-wrapper {
    margin-left: 1em;
  }
}

.game-dota {
  &.container {
  }

  .header {
    flex-wrap: wrap;
  }
  .timer-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.4em;
    position: relative;
    z-index: 2;

    &:after {
      content: '';
      width: 100%;
      position: absolute;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2);
      top: calc(50% - 1px);
      z-index: -1;
    }
  }
  .timer {
    --ui-timer-theme-bg: #17ace2;
  }
  .accent {
    color: #17ace2;
  }
}
</style>
