<template>
  <article :class="[$style.banner, $style[`game-${game}`]]">
    <h3 :class="$style.title">
      <span>{{ t('title') }}</span>
    </h3>
    <p :class="$style.text">
      <span>{{ maxCount }} {{ t('text') }}</span>
    </p>
    <ui-button
      @click="onClick"
      size="medium"
      theme="black"
      fill
      uppercase
      :class="$style.button"
      :loading="pending"
      v-analytics.click.gtm="['paripass', 'fantasy', 'banner_click']"
    >
      {{ t('Get cards') }}
    </ui-button>
  </article>
</template>

<i18n>
  {
    "ru": {
      "title": "Бесплатный набор",
      "subtitle": "Снайперы",
      "text": "карточек",
      "Get cards": "Получить"
    }
  }
</i18n>

<script setup>
import UiButton from '~/components/ui/button'
import { useFantasyShopCardsStore } from '@/stores/fantasy/shop-cards.js'

const fantasyShopCardListStore = useFantasyShopCardsStore()
const { $normalizePath } = useNuxtApp()
const { t } = useI18n()
const $props = defineProps({
  cards: {
    type: Object,
    required: true
  },
  game: {
    type: String,
    required: true
  }
})

const pending = ref(false)
const maxCount = computed(() => {
  const counts = Object.values($props.cards)
    .map(item => Object.values(item))
    .map(item => item.reduce((r, i) => +i + r, 0))
  return Math.max.apply(null, counts)
})

const onClick = async () => {
  try {
    pending.value = true
    await fantasyShopCardListStore.claimFreeCards()
    navigateTo($normalizePath('/fantasy/collection/'))
  } finally {
    pending.value = false
  }
}
</script>

<style lang="scss" module>
.game-cs {
  &.banner {
    background-image: url('@/components/fantasy/banners/free-cards/assets/csgo/bg-image.png');

    @include hiDPI {
      background-image: url('@/components/fantasy/banners/free-cards/assets/csgo/bg-image@2x.png');
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #0a8ec4 0%, rgba(76, 23, 226, 0) 100%);
      border-radius: inherit;
      filter: blur(17px);
      z-index: -1;
    }
  }
}

.game-dota {
  &.banner {
    background-image: url('@/components/fantasy/banners/free-cards/assets/dota/bg-image.jpg');

    @include hiDPI {
      background-image: url('@/components/fantasy/banners/free-cards/assets/dota/bg-image@2x.jpg');
    }
  }
}

.banner {
  max-width: 42em;
  box-sizing: border-box;
  border-radius: 1.1em;
  padding: 2.4em;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
}

.title {
  color: #ffffff;
  margin-bottom: 1.2em;

  span {
    display: block;
    font-weight: 700;
    font-size: 2.4em;
    line-height: 1.2;
  }
}

.text {
  margin-bottom: 3.6em;

  span {
    font-weight: 600;
    font-size: 1.4em;
    line-height: 1.3;
  }
}
.button {
  min-width: 16em;
}
</style>
