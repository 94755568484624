<template>
  <div :class="classes" @click="onClickItem" :data-game="game">
    <fantasy-common-card
      v-if="card"
      :poster="card.cardMember.currentCard.player.image_urls"
      :label="card.cardMember.currentCard.grade.name"
      :name="card.cardMember.currentCard.player.name"
      :sub-name="card.cardMember.currentCard.player.team.name"
      :icon="card.cardMember.currentCard.type.id"
      :type="card.cardMember.currentCard.grade.id"
      :events="card.cardMember.currentCard.labels"
      :game="card.cardMember.currentCard.type.game_id"
    />

    <fantasy-common-slot
      :class="$style.placeholder"
      :locked="locked"
      :game="game"
      :change="!!card"
      :card-type="typeId"
      :transparent="!!card"
    />
  </div>
</template>

<script setup>
import FantasyCommonSlot from '~/components/fantasy/common/slot'
import FantasyCommonCard from '~/components/fantasy/common/card'

const $emit = defineEmits(['click'])
const $style = useCssModule()

const $props = defineProps({
  card: {
    type: Object,
    default: null
  },
  typeId: {
    type: String,
    required: true
  },
  locked: {
    type: Boolean,
    default: false
  },
  game: {
    type: String,
    required: true
  }
})

const classes = computed(() => [
  $style.item,
  $props.locked && $style.locked,
  $props.card && $style['has-card']
])

const onClickItem = () => {
  if (!$props.locked) {
    $emit('click')
  }
}
</script>

<style lang="scss" module>
.item {
  position: relative;
  border-radius: 1.2em;
  transition:
    0.3s transform,
    0.3s box-shadow;

  > * {
    height: 100%;
    width: 100%;
  }
  //@include up(sm) {
  //  &:not(.locked) {
  //    &:hover {
  //      cursor: pointer;
  //      z-index: 5;
  //      transform: translate(0, -2em);
  //      box-shadow: 0 0.5em 5em rgba(0, 199, 177, 0.7);
  //    }
  //  }
  //}
}

.has-card .placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease;
  border-radius: inherit;
}
.has-card:hover .placeholder {
  opacity: 1;
}
.locked {
  pointer-events: none;
}

[data-game='cs'].item {
  @include up(sm) {
    transition:
      0.3s transform,
      0.3s box-shadow;
    &:hover {
      transform: translate(0, -2em);
      box-shadow: 0 5px 50px rgba(0, 199, 177, 0.7);
      z-index: 2;
    }
  }
}
</style>
