<template>
  <article :class="$style.banner" :data-game="game">
    <div :class="$style.content">
      <h3 :class="$style.title">
        <span>
          {{ t('title') }}
        </span>
      </h3>
      <div :class="$style.text">
        <p>{{ t('text') }} {{ percent }}%</p>
      </div>
      <ui-button
        v-if="canClaim"
        size="medium"
        theme="black"
        uppercase
        fill
        :class="$style.button"
        :loading="loading"
        @click="onClaim"
        v-analytics.click.gtm="['paripass', 'fantasy', 'banner_click']"
      >
        {{ t('buttonText') }}
      </ui-button>
    </div>
  </article>
</template>

<i18n>
{
    "ru": {
        "title": "Сезон завершен",
        "text": "Ты топ",
        "buttonText": "Награда"
    }
}
</i18n>

<script setup>
import UiButton from '~/components/ui/button'
import { useFantasySeasonStore } from '@/stores/fantasy/season.js'

const { t } = useI18n()
const { $dialog } = useNuxtApp()
const fantasySeasonStore = useFantasySeasonStore()

const loading = ref(false)

const $props = defineProps({
  percent: {
    type: Number,
    default: 100
  },
  canClaim: {
    type: Boolean,
    default: false
  },
  rewards: {
    type: Array,
    default: () => []
  },
  game: {
    type: String,
    required: true
  }
})

const onClaim = async () => {
  try {
    const reward = $props.rewards.find(item => item.is_available)
    loading.value = true
    await fantasySeasonStore.claimSeasonReward()
    $dialog({
      content: defineAsyncComponent(
        () => import('~/components/fantasy/popups/claimed')
      ),
      wrapper: null,
      contentProps: { reward }
    })
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" module>
.banner {
  position: relative;

  .content {
    box-sizing: border-box;
    padding: 2.4em;
    border-radius: 1em;
    color: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
    background-repeat: no-repeat;
  }

  .title {
    span {
      font-size: 1.6em;
      line-height: 1.5;
    }
  }

  .text {
    margin-bottom: 6em;
    font-family: var(--primary-font);

    @include down(sm) {
      margin-bottom: 10.6em;
    }

    p {
      font-weight: 700;
      font-size: 3.2em;
      line-height: 1.31;
    }
  }

  .button {
    width: 16em;
  }
}

.banner[data-game='cs'] {
  &.banner {
    .content {
      background-image: url(@/components/fantasy/banners/end-of-season/assets/csgo/bg-image.png),
        linear-gradient(0deg, #0a8ec4 0%, #17e274 100%);
      background-repeat: no-repeat;
      background-position: 100% 100%;
      background-size:
        auto 100%,
        contain;

      @include hiDPI {
        background-image: url(@/components/fantasy/banners/end-of-season/assets/csgo/bg-image@2x.png),
          linear-gradient(0deg, #0a8ec4 0%, #17e274 100%);
      }

      @include down(sm) {
        background-size:
          auto 83%,
          contain;
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(0deg, #0a8ec4 0%, #17e274 100%);
      filter: blur(1.7em);
      border-radius: 1em;
    }
  }
}

.banner[data-game='dota'] {
  &.banner {
    .content {
      background-position: center;
      background-size: cover;
      background-image: url(@/components/fantasy/banners/end-of-season/assets/dota/bg-image.jpg);

      @include hiDPI {
        background-image: url(@/components/fantasy/banners/end-of-season/assets/dota/bg-image@2x.jpg);
      }
    }
  }
}
</style>
