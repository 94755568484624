<template>
  <ui-link :to="to" :class="$style.button">
    <img src="./assets/icon-cards.svg?url" alt="" :class="$style.icon" />
    <span :class="$style.content">
      <span :class="$style.title">
        <span :class="$style.text">{{ t('title') }}</span>
      </span>
      <span :class="$style.text">{{ t('text') }} {{ count }}</span>
    </span>
  </ui-link>
</template>

<i18n>
{
  "ru": {
    "title": "Коллекция",
    "text": "Карточки"
  }
}
</i18n>

<script>
import UiLink from '~/components/ui/link'

export default {
  name: 'FantasyButtonCollection',
  components: {
    UiLink
  },
  props: {
    to: {
      type: String,
      default: '/'
    },
    count: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<style lang="scss" module>
.button {
  display: flex;
  align-items: center;
  position: relative;
  background: #000000;
  border-radius: 0.6em;
  padding: 1.2em 2.4em;
  box-sizing: border-box;
  max-width: 20em;
  transition: 0.3s box-shadow;
  @include down(sm) {
    justify-content: center;
  }
  &:hover {
    box-shadow: 0 6px 11px rgba(7, 239, 156, 0.27);
  }
}
.icon {
  width: 3.2em;
  height: 3.2em;
  margin-right: 1.6em;
}
.content {
  display: block;
}
.title {
  margin-bottom: 0.3em;
  display: block;
  .text {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.6em;
    line-height: 1.2;
    color: #00c7b1;
  }
}

.text {
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1.2;
}
</style>
