<template>
  <article :class="[$style.banner, $style[`game-${game}`]]">
    <div :class="$style.text1">
      <span :class="$style.text">
        <ui-time :value="timestamp" to-ms />
        {{ t('you got in') }}:
      </span>
    </div>
    <div :class="$style.text2">
      <span :class="$style.text">
        {{ t('Top') }} {{ percent || 100 }}% {{ t('players') }}
      </span>
    </div>
  </article>
</template>

<i18n>
{
  "ru": {
    "Top": "Топ",
    "you got in": "ты попал в",
    "players": "игроков"
  }
}
</i18n>

<script>
import UiTime from '~/components/ui/time'

export default {
  name: 'FantasyBannersDayStatus',
  components: {
    UiTime
  },
  props: {
    timestamp: {
      type: Number,
      default: 0
    },
    percent: {
      type: Number,
      default: 100
    },
    game: {
      type: String,
      required: true
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<style lang="scss" module>
.game-cs {
  &.banner {
    background: linear-gradient(
      0deg,
      #0a8ec4 0%,
      #23529d 30.62%,
      #2b326f 66.21%,
      #282c4d 100%
    );

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: linear-gradient(0deg, #0a8ec4 0%, rgba(76, 23, 226, 0) 100%);
      filter: blur(17px);
      z-index: -1;
    }
  }
}
.game-dota {
  &.banner {
    background-position: center;
    background-size: cover;
    background-image: url(@/components/fantasy/banners/day-status/assets/dota/bg-image.jpg);

    @include hiDPI {
      background-image: url(@/components/fantasy/banners/day-status/assets/dota/bg-image@2x.jpg);
    }
  }
}
.banner {
  position: relative;
  border-radius: 1em;
  padding: 2.4em 2.4em 2.2em;
  background-repeat: no-repeat;
}
.text1 {
  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6em;
    line-height: 150%;
  }
}
.text2 {
  .text {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 3.2em;
    line-height: 131%;
  }
}
</style>
