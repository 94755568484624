<template>
  <article :class="[$style.banner, $style[`game-${$props.game}`]]">
    <h3 :class="$style.title">
      <span>{{ t("Don't miss the benefit!") }}</span>
      <span>{{ t('title') }}</span>
    </h3>

    <p :class="$style.text"></p>

    <ui-button
      to="/fantasy/shop"
      size="medium"
      theme="black"
      fill
      uppercase
      :class="$style.button"
      v-analytics.click.gtm="['paripass', 'fantasy', 'banner_click']"
    >
      {{ t('Buy') }}
    </ui-button>
  </article>
</template>

<i18n>
  {
    "ru": {
      "title": "Купи набор со скидкой",
      "subtitle": "Снайперы",
      "text": "8 карточек",
      "Don't miss the benefit!": "Не упусти выгоду!",
      "Buy": "Купить"
    }
  }
</i18n>

<script setup>
import UiButton from '~/components/ui/button'

const $props = defineProps({
  game: {
    type: String,
    required: true
  }
})

const { t } = useI18n()
</script>

<style lang="scss" module>
.game-cs {
  &.banner {
    background-image: url(@/components/fantasy/banners/shop/assets/csgo/bg-image.png);

    @include hiDPI {
      background-image: url(@/components/fantasy/banners/shop/assets/csgo/bg-image@2x.png);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #0a8ec4 0%, rgba(76, 23, 226, 0) 100%);
      border-radius: inherit;
      filter: blur(17px);
      z-index: -1;
    }
  }
}

.game-dota {
  &.banner {
    background-image: url(@/components/fantasy/banners/shop/assets/dota/bg-image.jpg);

    @include hiDPI {
      background-image: url(@/components/fantasy/banners/shop/assets/dota/bg-image@2x.jpg);
    }
  }
}

.banner {
  max-width: 42em;
  box-sizing: border-box;
  border-radius: 1.1em;
  padding: 2.4em;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
}

.title {
  color: #ffffff;
  margin-bottom: 1.2em;

  span {
    display: block;
    margin-bottom: 0.8em;
    &:first-child {
      font-weight: 400;
      font-size: 2em;
      line-height: 1.2;
    }

    &:last-child {
      font-weight: 700;
      font-size: 2.2em;
      line-height: 1.2;
    }
  }
}

.text {
  margin-bottom: 3.6em;

  span {
    font-weight: 600;
    font-size: 1.4em;
    line-height: 1.3;
  }
}
.button {
  min-width: 16em;
}
</style>
