<template>
  <div :class="$style.container" :data-game="game">
    <div :class="$style.slots" v-if="game === 'cs'">
      <fantasy-game-day-team-item
        v-for="item in items"
        :key="item.position.id"
        :type-id="item.position.type_id"
        :game="game"
        :card="item.slot"
        :locked="locked"
        :class="$style.item"
        @click="onClickItem(item.position)"
      />
    </div>

    <div :class="$style.groups" :data-groups="groups.length" v-else>
      <div
        v-for="(group, i) in groups"
        :key="i"
        :class="$style.group"
        :data-slots="group.positions.length"
        :data-type="group.type_id"
      >
        <div :class="$style.name">
          <span>{{ t(group.type_id) }}</span>
        </div>

        <div :class="$style.cards" :data-cards="group.positions.length">
          <fantasy-game-day-team-item
            v-for="position in group.positions"
            :key="position.id"
            :type-id="position.type_id"
            :card="position.slot"
            :game="game"
            :locked="locked"
            :class="$style.card"
            @click="onClickItem(position)"
          />
        </div>
      </div>
    </div>

    <fantasy-common-overlay-team-lock v-if="showOverlay" :show-message="true" />
  </div>
</template>

<i18n lang="json">
{
  "ru": {
    "main": "Основа",
    "middle": "Центр",
    "support": "Поддержка",
    "fighter": "Боец",
    "sniper": "Снайпер"
  },
  "en": {
    "main": "Main",
    "middle": "Center",
    "support": "Support",
    "fighter": "Fighter",
    "sniper": "Sniper"
  }
}
</i18n>

<script setup>
import FantasyCommonOverlayTeamLock from '~/components/fantasy/common/overlay/team-lock'
import FantasyGameDayTeamItem from '~/components/fantasy/game-day/team/item'
import { useFantasyMemberCardsStore } from '@/stores/fantasy/member-cards'

const { t } = useI18n()
const fantasyMemberCardsStore = useFantasyMemberCardsStore()
const { $matchMedia, $dialog } = useNuxtApp()

const $props = defineProps({
  dayId: {
    type: Number,
    required: true
  },
  positions: {
    type: Array,
    default: () => []
  },
  slots: {
    type: Array,
    default: () => []
  },
  locked: {
    type: Boolean,
    default: false
  },
  showOverlay: {
    type: Boolean,
    default: false
  },
  game: {
    type: String,
    required: true
  }
})

const items = computed(() => {
  return $props.positions.map(item => ({
    position: item,
    slot: $props.slots.find(slot => slot.position === item.id)
  }))
})

const groups = computed(() =>
  $props.positions.reduce((groups, position) => {
    let group = groups.find(item => item.type_id === position.type_id)
    if (!group) {
      group = { type_id: position.type_id, positions: [] }
      groups.push(group)
    }
    const data = {
      id: position.id,
      type_id: position.type_id,
      slot: $props.slots.find(slot => slot.position === position.id)
    }
    group.positions.push(data)
    return groups
  }, [])
)
const itemsPerSlide = computed(() =>
  $matchMedia.down('sm') ? 4 : $matchMedia.down('md') ? 6 : 5
)

const onClickItem = ({ id: positionId, type_id: typeId }) =>
  $dialog({
    content: defineAsyncComponent(
      () => import('~/components/fantasy/popups/card/select/index.vue')
    ),

    contentProps: {
      dayId: $props.dayId,
      positionId,
      typeId,
      excludeIds: $props.slots.map(item => item.card_member_id)
    },

    wrapper: defineAsyncComponent(
      () => import('~/components/fantasy/popups/card-wrapper.vue')
    ),

    wrapperProps: {
      unlimitedWidth: true,
      circularCloseButton: true
    },

    fetchData: () =>
      fantasyMemberCardsStore.fetchItems({
        'filter[type_id]': typeId,
        perPage: itemsPerSlide.value
      })
  })
</script>

<style lang="scss" module>
.container {
  --fantasy-team-group-bg: transparent;
  position: relative;
  border-radius: 1.2em;
}

.container[data-game='dota'] {
  --fantasy-team-group-bg: #0f1936;
}

.groups {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.5em;
  @include down(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.group {
  background: var(--fantasy-team-group-bg);
  padding: 1.2em 1em 1em;
  border-radius: 1.2em;
  display: flex;
  flex-direction: column;

  &[data-slots='1'] {
    grid-column: span 1;
  }
  &[data-slots='2'] {
    grid-column: span 2;
  }
  &[data-slots='3'] {
    grid-column: span 3;
  }
  &[data-slots='4'] {
    grid-column: span 4;
  }
  &[data-slots='5'] {
    grid-column: span 5;
  }

  @include down(md) {
    &[data-type='middle'] {
      order: 0;
    }
    &[data-type='main'] {
      order: 1;
    }
    &[data-type='support'] {
      order: 2;
    }
    &[data-slots='1'][data-type='middle'] {
      grid-column: span 2;
    }
  }
}
.name {
  margin-bottom: 1.6em;
  text-align: center;
  span {
    font-family: var(--primary-font);
    font-size: 1.6em;
    font-weight: 700;
    letter-spacing: -0.02em;
  }
}
.cards {
  display: grid;
  align-items: stretch;
  gap: 1.2em;
  flex: 1 1;
  @include down(sm) {
    gap: 0.8em;
  }
  &[data-cards='1'] {
    grid-template-columns: 1fr;
    @include down(md) {
      grid-template-columns: repeat(1, 0.5fr);
      justify-content: center;
    }
  }
  &[data-cards='2'] {
    grid-template-columns: repeat(2, 1fr);
  }
  &[data-cards='3'] {
    grid-template-columns: repeat(3, 1fr);
  }
  &[data-cards='4'] {
    grid-template-columns: repeat(4, 1fr);
  }
  &[data-cards='5'] {
    grid-template-columns: repeat(5, 1fr);
  }
}
.card {
  flex: 1 1;
  min-width: 0;
}

.slots {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.8em;
  @include down(sm) {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8em;
    justify-content: center;
    .item {
      flex: 0 1 47%;
    }
  }
}
.item {
  min-width: 0;
  @include down(sm) {
    flex: 0 0 50%;
    padding: 0.35em;
  }
}
</style>
